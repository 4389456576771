import React from 'react'
import { Container, Row, Col, Badge, Button } from 'react-bootstrap'
import { servicesFilterClientPage, industriesFilterClientPage } from '../../staticdata/caseStudySectionData'

const ClientFilters = ({ data, selectService, selectedService, removeItem, selectedIndustries, selectIndustry }) => {
	return (
		<>
			<Container className="mt-5">
				<Row>
					<Col>
						<h4 className='text-std'>All Stories ({data && data.length})</h4>
					</Col>
					<Col md="9">
						<Row>
							<Col md="6">
								<label htmlFor="service">Service:</label>
								<select
									name="service"
									className="form-select custom-select--transparent mb-3 text-std"
									data-type="service"
									onChange={(event) => selectService(event.target.value)} >
									{
										servicesFilterClientPage.map(service => (
											<option key={service.label} value={service.label}>{service.label}</option>
										))
									}
								</select>
							</Col>
							<Col md="6">
								<label htmlFor="industry">Industries:</label>
								<select
									name="industry"
									className="form-select custom-select--transparent mb-3 text-std"
									data-type="industry"
									onChange={(event) => selectIndustry(event.target.value)} >
									{
										industriesFilterClientPage.map(service => (
											<option key={service.label} value={service.label}>{service.label}</option>
										))
									}
								</select>
							</Col>
						</Row>
					</Col>
				</Row>
				<hr />
			</Container>
			{((selectedService && selectedService.length > 0) || (selectedIndustries && selectedIndustries.length > 0)) && <Container>
				<Row>
					<Col md="2">
						<Button onClick={() => removeItem(null, 'all')}>Clear All</Button>
					</Col>
					<Col>
						{
							selectedService.map(service => (
								service !== 'All' && <Badge bg="secondary">{service} <button onClick={() => removeItem(service, 'service')}>x</button></Badge>
							))
						}
						{
							selectedIndustries.map(industry => (
								industry !== 'All' && <Badge bg="secondary">{industry} <button onClick={() => removeItem(industry, 'industry')}>x</button></Badge>
							))
						}
					</Col>
				</Row>
			</Container>}

		</>
	);
};

export default ClientFilters