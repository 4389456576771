import React from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap'

const ClientsComponent = ({ data }) => {
	const isOddItems = (data.length % 2) === 1
	return (
		<>
			<Container className="mt-5 d-none d-md-flex">
				<Row>
					{
						data.map((card, index) => {
							if ((index + 1) === data.length && isOddItems) {
								return (
									<Row key={card.redirection} className="mt-3">
										<Card style={{ border: 'none' }}>
											<Row noGutters>
												<Col md="7" style={{ overflow: 'hidden', padding: '0' }}>
													<a style={{ overflow: 'hidden' }} href={card.redirection}>
														<Card.Img variant="top" src={card.imageSrc} className="image d-block client-card-image" />
													</a>
												</Col>
												<Col md="5">
													<Card.Body className="client-card-body-single">
														<Card.Title>
															<a href={card.redirection}>{card.mainHeading}</a></Card.Title>
														<Card.Text>
															<a style={{ color: 'yellow' }} className='text-std' href={card.redirection}>{card.description.substr(0, 150)}...</a>
														</Card.Text>
													</Card.Body>
												</Col>

											</Row>
										</Card>

									</Row>
								)
							} else {
								return (
									<Col md="6" key={card.redirection} className="mt-4">
										<Card style={{ border: 'none' }}>
											<a style={{ overflow: 'hidden' }} href={card.redirection}>
												<Card.Img variant="top" src={card.imageSrc} className="image d-block client-card-image" />
											</a>
											<Card.Body className="client-card-body">
												<Card.Title>
													<a href={card.redirection}>{card.mainHeading}</a></Card.Title>
												<Card.Text>
													<a style={{ color: 'yellow' }} className='text-std' href={card.redirection}>{card.description.substr(0, 150)}...</a>
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								)
							}
						})
					}
				</Row>

			</Container>

			<Container className="mt-5 d-md-none d-flex">
				<Row>
					{
						data.map((card, index) => {
							return (
								<Col md="6" key={card.redirection} className="mt-2">
									<Card style={{ border: 'none' }}>
										<a style={{ overflow: 'hidden' }} href={card.redirection}>
											<Card.Img variant="top" src={card.imageSrc} className="image d-block client-card-image" />
										</a>
										<Card.Body className="client-card-body">
											<Card.Title>
												<a href={card.redirection}>{card.mainHeading}</a></Card.Title>
											<Card.Text>
												<a style={{ color: 'yellow' }} href={card.redirection}>{card.description.substr(0, 150)}...</a>
											</Card.Text>
										</Card.Body>
									</Card>
								</Col>
							)
						})
					}
				</Row>

			</Container>
			<Container >
				<hr />
			</Container>

		</>
	);
};

export default ClientsComponent;